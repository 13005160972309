<template>
  <div
    class="my_box"
    v-loading="loading"
    element-loading-text="注册中，请稍后..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(255, 255, 255, 0.8)"
  >
    <p class="title">用户注册</p>

    <el-form class="my_form" :model="form" ref="form" :rules="rules">
      <el-form-item
        v-if="registrationCodeStatus == 1"
        prop="registerCode"
        :rules="[{ required: true, message: '请输入注册码', trigger: 'blur' }]"
      >
        <el-input placeholder="注册码" v-model="form.registerCode"></el-input>
      </el-form-item>
      <el-form-item
        prop="mobile"
        :rules="[
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            pattern: /^1\d{10}$/,
            message: '请输入正确的号码格式',
            trigger: 'change',
          },
        ]"
      >
        <el-input placeholder="手机号" v-model="form.mobile"></el-input>
      </el-form-item>
      <el-form-item
        prop="msgCode"
        :rules="[{ required: true, message: '请输入验证码', trigger: 'blur' }]"
      >
        <el-input
          placeholder="验证码"
          v-model="form.msgCode"
          style="width: 60%"
        ></el-input>
        <span class="send" @click="sendCode" v-if="!isDownTime"
          >获取验证码</span
        >
        <span class="send" v-else>{{ codeNum }}s后重新发送</span>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          :type="passwordType"
          placeholder="设置8-16位（大写字母/小写字母+数字+字符）新密码"
          v-model="form.password"
          show-password
        >
          <template v-if="form.password" slot="append">{{
            classTyle
          }}</template>
        </el-input>
      </el-form-item>
      <el-form-item
        prop="repassword"
        :rules="[{ required: true, validator: validatePass2, trigger: 'blur' }]"
      >
        <el-input
          :type="passwordType1"
          placeholder="再次确认密码"
          v-model="form.repassword"
           show-password
        >
        
        </el-input>
      </el-form-item>
      <div class="btn">
        <el-button type="primary" @click="handelRegister('form')"
          >注 册</el-button
        >
      </div>
    </el-form>
    <div class="tips">
      <p>
        已有账号，
        <router-link to="/guide/login" replace>马上登录</router-link>
      </p>
    </div>
  </div>
</template>

<script>
import { register, registerSendMsg, companyInfo } from "@/util/api";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (!this.validateString(value)) {
          callback(
            new Error("密码必须包含数字、大小写字母及符号，且长度不能小于8位")
          );
          this.classTyle = "低";
        } else {
          if (value.length < 8) {
            callback(
              new Error("密码必须包含数字、大小写字母及符号，且长度不能小于8位")
            );
            this.classTyle = "低";
          } else if (value.length < 13) {
            this.classTyle = "中";
            if (this.form.repassword !== "") {
              this.$refs.form.validateField("repassword");
            }
            callback();
          } else {
            this.classTyle = "高";
            if (this.form.repassword !== "") {
              this.$refs.form.validateField("repassword");
            }
            callback();
          }
        }
      }
    };
    return {
      classTyle: "低",
      form: {
        registerCode: "",
        mobile: "",
        msgCode: "",
        password: "",
        repassword: "",
        domainUrl: window.location.hostname,
      },
      passwordType: "password",
      passwordType1: "password",
      loading: false,
      codeNum: 120,
      timmer: null,
      isDownTime: false,
      registrationCodeStatus: 0,
      rules: {
        password: [{ validator: validatePass, trigger: "blur" }],
      },
    };
  },
  mounted() {
    companyInfo({ domainUrl: window.location.hostname })
      .then((res) => {
        this.registrationCodeStatus = res.data.registrationCodeStatus;
      })
      .catch((err) => {});
  },
  methods: {
    validateString(str) {
      // 正则表达式，分别检查四个条件
      const hasDigit = /.*\d.*/;
      const hasLowerCase = /.*[a-z].*/;
      const hasUpperCase = /.*[A-Z].*/;
      const hasSpecialChar = /.*[^\da-zA-Z].*/;

      // 检查每个条件是否满足，并计算满足条件的数量
      let count = 0;
      if (hasDigit.test(str)) count++;
      if (hasLowerCase.test(str)) count++;
      if (hasUpperCase.test(str)) count++;
      if (hasSpecialChar.test(str)) count++;
      // 检查是否至少满足三个条件
      return count >= 3;
    },
    // 小眼睛
    showPassword() {
      this.passwordType == ""
        ? (this.passwordType = "password")
        : (this.passwordType = "");
    },
    showPassword1() {
      this.passwordType1 == ""
        ? (this.passwordType1 = "password")
        : (this.passwordType1 = "");
    },
    // 匹配两次密码
    validatePass2(rule, value, callback) {
      if (value === "") {
        callback(new Error("请再次输入密码"));
        // password 是表单上绑定的字段
      } else if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    },
    // 注册事件
    handelRegister(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          register(this.form)
            .then((res) => {
              this.$notify({
                title: "成功",
                message: "注册成功！即可登陆",
                type: "success",
              });
              this.loading = false;
              this.$router.replace("/guide");
            })
            .catch((err) => {
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 发送验证码
    sendCode() {
      if (this.form.mobile) {
        registerSendMsg({ mobile: this.form.mobile })
          .then((res) => {
            this.isDownTime = true;
            this.$message.success(
              `验证码已发送到${this.form.mobile}上，注意查收`
            );
            this.timmer = setInterval(() => {
              if (this.codeNum > 0) {
                this.codeNum--;
              } else {
                this.isDownTime = false;
                this.codeNum = 120;
                clearInterval(this.timmer);
              }
            }, 1000);
          })
          .catch((err) => {
            clearInterval(this.timmer);
            this.isDownTime = false;
          });
      } else {
        this.isDownTime = false;
        this.$message.warning("请输入手机号！");
      }
      //  registerSendMsg({

      //  })
    },
  },
};
</script>

<style lang="scss" scoped>
p.title {
  font-size: 26px;
  color: #333333;
  padding-top: 50px;
  text-align: center;
}
.my_form {
  width: 65%;
  margin: 30px auto;
   ::v-deep .el-input-group__append {
    margin-left: 5px;
    border-radius: 10px;
  }
}
.el-form-item {
  margin-bottom: 30px;
  position: relative;
}
.my_icon {
  font-size: 16px;
  margin-left: 20px;
}
.other_control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    cursor: pointer;
  }
}
.btn {
  width: 100%;
  margin-top: 50px;
  button {
    width: 100%;
    border-radius: 25px;
    background-color: #5586e1;
  }
}
.tips {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
  p {
    font-size: 14px;
    color: #666;
    a {
      color: #5586e1;
      text-decoration: none;
    }
  }
}
.send {
  width: 120px;
  height: 40px;
  border: 1px solid #5586e1;
  border-radius: 10px;
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5586e1;
  cursor: pointer;
  top: 0;
}
</style>
<style scoped>
.my_form >>> .el-input__inner {
  border-radius: 10px;
}
</style>
